import React from "react";
import "./Header.css";
import Logo from "./logo/Logo";
import Navigation from "./navigation/Navigation";
import MobileNavigation from "./navigation/MobileNavigation";

const Header = () => {

    return (
        <div id="header" className="header">
            <Logo />
            <Navigation />
            <MobileNavigation />
        </div>
    )
}

export default Header