// FAQ
const faq = document.querySelectorAll(".js-faq");

function toggle() {
    const content = this.nextElementSibling;
    this.classList.toggle("is-active");
    content.classList.toggle("is-open");
}

for (let i = 0; i < faq.length; i++) {
    faq[i].addEventListener("click", toggle);
}