import React, { useState } from "react";
import "./Navigation.css";
import Instagram from "../../socials/Instagram";
import Twitter from "../../socials/Twitter";
import Discord from "../../socials/Discord";
import Opensea from "../../socials/Opensea";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const Navigation = () => {

    const [navStyle, setnavStyle] = useState({
        transition: 'all 400ms linear'
      })
      
      useScrollPosition(
        ({ prevPos, currPos }) => {
          const isVisible = currPos.y > prevPos.y
      
          const shouldBeStyle = {
            visibility: isVisible ? 'visible' : 'hidden',
            transition: `all 400ms ${isVisible ? 'linear' : 'linear'}`,
            transform: isVisible ? 'none' : 'translate(0, -100%)'
          }
      
          if (JSON.stringify(shouldBeStyle) === JSON.stringify(navStyle)) return
      
          setnavStyle(shouldBeStyle)
        },
        [navStyle]
      )


    return (
        <div className="navigation-outer">
            <nav className="navigation" style={{ ...navStyle }}>
                <ul>
                    <div className="nav-items">
                        <li>
                            <a href="https://instagram.com/hjkpunks" target="_blank" rel="noreferrer" className="instagram"><Instagram className={"instagram-icon"} width={17} height={17} fill={"#000"} /><span className="nav-text">Instagram</span></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/hjkpunks" target="_blank" rel="noreferrer" className="twitter"><Twitter className={"twitter-icon"} width={17} height={17} fill={"#000"} /><span className="nav-text">Twitter</span></a>
                        </li>
                        <li>
                            <a href="https://discord.gg/Tt7t2VgBBk" target="_blank" rel="noreferrer" className="discord"><Discord className={"discord-icon"} width={17} height={17} fill={"#000"} /><span className="nav-text">Discord</span></a>
                        </li>
                        <li>
                            <a href="https://opensea.io/collection/harajuku-punks" target="_blank" rel="noreferrer" className="opensea"><Opensea className={"opensea-icon"} width={17} height={17} fill={"#000"} /><span className="nav-text">OpenSea</span></a>
                        </li>
                        <li className="in-site">
                            <AnchorLink href="#characters" className="scroll-to">Characters</AnchorLink>
                        </li>
                        <li className="in-site">
                            <AnchorLink href="#roadmap" className="scroll-to">Roadmap</AnchorLink>
                        </li>
                        <li className="in-site">
                            <a className="change-lang" href="/jp">日本語</a>
                        </li>
                    </div>
                </ul>
            </nav>
        </div>
    );
}

export default Navigation;
