import React from "react";
import "./Mint.css";
import Tslider from "../tslider/Tslider";

const Mint = () => {
    return (
        <div className="mint">
            <Tslider />
            <div className="mint-content">
                <div className="mint-bg">
                    <div className="mint-bg-img" />
                </div>
                <h2 className="mint-title">
                    We're still on the way, but still cheap!
                </h2>
                <a href="https://mint.harajukupunks.com" target="_blank" rel="noreferrer" className="mint-mint-btn">Mint Now</a>       
            </div>
        </div>
    )
}

export default Mint