import React from "react";
import "./Style.css";
import "./Style.js";
import Header from "./components/header/Header";
import Imp from "./components/impression/Impression";
import Details from "./components/details/Details";
import Chara from "./components/characters/Characters";
import Roadmap from "./components/roadmap/Roadmap";
import Mint from "./components/mint/Mint";
import Team from "./components/team/Team";
import Faqs from "./components/faq/Faqs";
import Footer from "./components/footer/Footer";

const En = () => {
    return(
        <div className="wrapper">
            <div className="wrapper-bg" />
            <Header />
            <Imp />
            <Details />
            <Chara />
            <Roadmap />
            <Mint />
            <Team />
            <Faqs />
            <Footer />        
        </div>     
    )
}
export default En