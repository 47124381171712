import React from "react";
import "./Navigation.css";
import Instagram from "../../socials/Instagram";
import Twitter from "../../socials/Twitter";
import Discord from "../../socials/Discord";
import Opensea from "../../socials/Opensea";
import AnchorLink from "react-anchor-link-smooth-scroll";


const NavLinks = (props) => {
    return (
        <ul>
            <div className="nav-items">
                <li>
                    <a href="https://instagram.com/hjkpunks" target="_blank" rel="noreferrer" className="instagram"><Instagram className={"instagram-icon"} width={17} height={17} fill={"#000"} /><span className="nav-text">Instagram</span></a>
                </li>
                <li>
                    <a href="https://twitter.com/hjkpunks" target="_blank" rel="noreferrer" className="twitter"><Twitter className={"twitter-icon"} width={17} height={17} fill={"#000"} /><span className="nav-text">Twitter</span></a>
                </li>
                <li>
                    <a href="https://discord.gg/Tt7t2VgBBk" target="_blank" rel="noreferrer" className="discord"><Discord className={"discord-icon"} width={17} height={17} fill={"#000"} /><span className="nav-text">Discord</span></a>
                </li>
                <li>
                    <a href="https://opensea.io/collection/harajuku-punks" target="_blank" rel="noreferrer" className="opensea"><Opensea className={"opensea-icon"} width={17} height={17} fill={"#000"} /><span className="nav-text">OpenSea</span></a>
                </li>
                <li onClick={() => props.closeMobileNav()}>
                    <AnchorLink href="#characters" className="scroll-to">Characters</AnchorLink>
                </li>
                <li onClick={() => props.closeMobileNav()}>
                    <AnchorLink href="#roadmap" className="scroll-to">Roadmap</AnchorLink>
                </li>
                <li>
                    <a className="change-lang" href="/">English</a>
                </li>
            </div>
        </ul>
    );
}

export default NavLinks;
