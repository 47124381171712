import React from "react";
import { useState } from "react";
import "./Navigation.css";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import NavLinks from "./NavLinks";

const MobileNavigation = () => {
    const [open, setOpen] = useState(false);
    const openIcon = <AiOutlineMenu className="nav-opener" 
                        size="30px" 
                        color="#000" 
                        onClick={() => setOpen(!open)}
            />
    const closeIcon = <AiOutlineClose className="nav-closer"
                        size="30px"
                        color="#000"
                        onClick={() => setOpen(!open)}
            />
    const closeMobileNav = () => setOpen(false);

    return (
        <nav className="mobile-navigation">
            {open ? closeIcon : openIcon}
            {open && <NavLinks closeMobileNav={closeMobileNav} />}
        </nav>
    );
}

export default MobileNavigation;
