import { BrowserRouter, Routes, Route } from "react-router-dom";
import { En, Jp } from "./templates";

const Router = () => {
    return (
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<En />} />
            <Route path="/jp" element={<Jp />} />
        </Routes>
      </BrowserRouter>
    )
  };
  
  export default Router;