import React from "react";
import "./Details.css";

const Details = () => {
    return (
        <div className="details">
            <div className="details-content">
                <h2 className="details-title">Hi Friends!</h2>
                <p className="details-text">
                Harajuku Punksは日本発、総数10000点のNFTコレクションです。それぞれが100以上のパーツの組み合わせからなる、完全プログラム生成された唯一無二のNFTで、通称HJKトークンと呼びます。<br/><br/>
                それぞれのトークンは、ERC-721規格に基づきイーサリアムブロックチェーン上で管理され、分散型ファイルシステムIPFS上に保存されています。HJKは当サイトにて0.02ETHから購入可能です。</p>
            </div>
        </div>
    )
}

export default Details