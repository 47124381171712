import React from "react";
import "./Footer.css";
import Tslider from "../tslider/Tslider";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Footer = () => {
    return (
        <div className="footer">
            <Tslider />
            <div className="footer-content">
                <h2 className="footer-title">
                    最初の100点限定<br/>HJKが0.02ETH！
                </h2>
                <a href="https://mint.harajukupunks.com" target="_blank" rel="noreferrer" className="footer-mint-btn">Mint Now</a>
                <div className="footer-bottom">
                    <AnchorLink href="#header" className="back-to-top" />
                    <span className="copyright" dangerouslySetInnerHTML={{ "__html": "&copy;2022 HARAJUKU PUNKS" }} />
                </div>
            </div>
        </div>
    )
}

export default Footer