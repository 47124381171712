import React from "react";
import Faq from "./Faq.js"
import "./Faq.css";

const Faqs = () => {
    return (
        <div className="faqs">
            <div className="faqs-content">
                <div className="faqs-bg">
                    <div className="faqs-bg-img" />
                </div>
                <h2 className="faqs-title">Still on a fence?(FAQ)</h2>
                <div className="faqs-block">
                    <Faq question="Harajuku Punksとは？" answer="街を闊歩しながら、手元の装置で変身。メタバース世界に飛び込もうとしている私たちの今の姿を、10000点のNFTアートとして描いたプロジェクトです。" />
                    <Faq question="NFTとは？" answer="Non-Fungible Token（ノン-ファンジャブル トークン）」の頭文字を取ったもので、日本語で「非代替性トークン」という意味です。ブロックチェーン技術を使用し発行される、唯一性のあるデジタル資産です。" />
                    <Faq question="HJKとは？" answer="Harajuku Punksの一つ一つのトークン(NFTアート)を、HJKと呼びます。" />
                    <Faq question="HJK(NFTアート)の入手に必要なもの" answer="HJKは、イーサリアムブロックチェーンを使用し発行されたトークンであるため、入手の際にMetaMask(メタマスク)などイーサリアム系の仮想通貨ウォレットが必要です。" />
                    <Faq question="HJK(NFTアート)の入手方法" answer="当サイトの「Mint Now」ボタンから、「Mint=アートの生成」を受付するページにアクセスできます。<br/><br/>
                                                                こちらで、MetaMaskなどを用いて仮想通貨イーサ(ETH)による決済を行なっていただくことで、新しいNFTアートが生成され、入手できます。<br/><br/>
                                                                尚、Mint時に発生する、イーサリアムブロックチェーンに対する手数料であるガス代は、Mint実行者の負担となります。" />
                    <Faq question="Mint後のHJK(NFTアート)の確認方法" answer="ご自身のウォレットや、OpenseなどのNFTマーケットプレイスにて確認が可能です。" />
                    <Faq question="HJK(NFTアート)ホルダーとしてできること" answer="NFTコレクションの一つとして、またSNSのアイコンとしてなど、自由にご利用いただけます。また、OpenSeaなどのNFTマーケットプレイス上で売りに出すことも可能です。" />
                </div>
            </div>
        </div>   
)
}

export default Faqs