import React, { useState, useRef } from "react";
import "./Faq.css";

function Faq(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setOpen, setOpenState] = useState("faq-icon");

  const content = useRef(null);

  function toggleFaq() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setOpenState(
      setActive === "active" ? "faq-icon" : "faq-icon open"
    );
  }
  
  return (
    <div className="faq">
      <button className={`faq-q ${setActive}`} onClick={toggleFaq}>
        <p className="faq-q-text">{props.question}</p>
        <div className={`${setOpen}`} />
      </button>
      <div 
        className="faq-a"
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        >
        <div
          className="faq-a-text"
          dangerouslySetInnerHTML={{ __html: props.answer }}
          />
      </div>
    </div>
  )
}

export default Faq