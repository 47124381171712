import React from "react";
import "./Roadmap.css";

const Roadmap = () => {
    return (
        <div id="roadmap" className="roadmap">
            <div className="roadmap-content">
                <h2 className="roadmap-title">Roadmap</h2>
                <div className="timeline">
                    <div className="line" />
                    <div className="phase">
                        <div className="phase-left">
                            <div className="roadmap-bg-1">
                                <div className="roadmap-bg-img-1" />
                            </div>
                        </div>
                        <div className="phase-right">
                            <div className="phase-details">
                                <div className="phase-text-area">
                                    <h3 className="phase-title">
                                    Finalizing HJK Arts
                                    </h3>
                                    <p className="phase-text">
                                    The HARAJUKU PUNKS will launch soon!<br/><br/>
                                    Keep an eye on our Twitter, Instagram and Discord not to miss latest update.
                                    </p>
                                </div>                           
                            </div>
                        </div>
                    </div>
                    <div className="phase">
                        <div className="phase-left">
                            <div className="phase-details">
                                <div className="current">
                                    <p className="current-text">
                                        We're here!
                                    </p>
                                </div>
                                <div className="phase-text-area">
                                    <h3 className="phase-title">
                                    First Drop
                                    </h3>
                                    <p className="phase-text">
                                    Limited number of HJK is available to be minted with 0.02 ETH through this web site.<br/><br/>
                                    Join our airdrop campaign to get one free HJK!
                                    </p>
                                </div>                           
                            </div>
                        </div>
                        <div className="phase-right">
                            <div className="roadmap-bg-2">
                                <div className="roadmap-bg-img-2" />
                            </div>
                        </div>
                    </div>
                    <div className="phase">
                        <div className="phase-left">
                            <div className="roadmap-bg-3">
                                <div className="roadmap-bg-img-3" />
                            </div>
                        </div>
                        <div className="phase-right">
                            <div className="phase-details">
                                <div className="phase-text-area">
                                    <h3 className="phase-title">
                                    Second Drop
                                    </h3>
                                    <p className="phase-text">
                                    All HJKs are available to be minted through this web site.
                                    </p>
                                </div>                           
                            </div>
                        </div>
                    </div>
                    <div className="phase">
                        <div className="phase-left">
                            <div className="phase-details">
                                <div className="phase-text-area">
                                    <h3 className="phase-title">
                                    HJK Community
                                    </h3>
                                    <p className="phase-text">
                                    Closed Community that only HJK holders can access is opened in our Discord.
                                    </p>
                                </div>                           
                            </div>
                        </div>
                        <div className="phase-right">
                            <div className="roadmap-bg-4">
                                <div className="roadmap-bg-img-4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roadmap