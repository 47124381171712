import React from "react";
import "./Details.css";

const Details = () => {
    return (
        <div className="details">
            <div className="details-content">
                <h2 className="details-title">Hi Friends!</h2>
                <p className="details-text">The Harajuku Punks are a collection of 10000 NFTs (non-fungible tokens). Each one of them is unique and programmatically generated from over 100 visual traits.<br/><br/>
                    The Harajuku Punks are stored as ERC-721 tokens on the Ethereum blockchain and hosted on IPFS. Purchasing the Harajuku Punks is available on OpenSea and it costs from 0.02 ETH.</p>
            </div>
        </div>
    )
}

export default Details