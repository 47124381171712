import React from "react";
import "./Characters.css";
import Cat1 from "../../img/cat1.png";
import Cat2 from "../../img/cat2.png";
import Shiba1 from "../../img/shiba1.png";
import Shiba2 from "../../img/shiba2.png";
import Fox1 from "../../img/fox1.png";
import Fox2 from "../../img/fox2.png";
import Other1 from "../../img/other1.png";
import Other2 from "../../img/other2.png";

const Chara = () => {
    return (
        <div id="characters" className="characters">
            <div className="characters-content">
                <div className="characters-bg">
                    <div className="characters-bg-img" />
                </div>
                <div className="characters-block">
                    <div className="characters-text-block">
                        <h3 className="characters-title">Cat</h3>
                        <p className="characters-text">
                            Needless to say, cat is an idle in animal world, isn't it? All you need is love and a cat!?
                        </p>
                    </div>
                    <div className="characters-img-block">
                        <div className="characters-img">
                            <img src={Cat1} />
                        </div>
                        <div className="characters-img">
                            <img src={Cat2} />
                        </div>
                    </div>
                </div>
                <div className="characters-block">
                    <div className="characters-text-block">
                        <h3 className="characters-title">Shibainu</h3>
                        <p className="characters-text">
                            A cute dog from Japan is always by your side. Friendship can go beyond species!
                        </p>
                    </div>
                    <div className="characters-img-block">
                        <div className="characters-img">
                            <img src={Shiba1} />
                        </div>
                        <div className="characters-img">
                            <img src={Shiba2} />
                        </div>
                    </div>
                </div>
                <div className="characters-block">
                    <div className="characters-text-block">
                        <h3 className="characters-title">Fox</h3>
                        <p className="characters-text">
                            A god leading you to success? or a yokai triking you? Fox is a mysterious animal that has many different faces.
                        </p>
                    </div>
                    <div className="characters-img-block">
                        <div className="characters-img">
                            <img src={Fox1} />
                        </div>
                        <div className="characters-img">
                            <img src={Fox2} />
                        </div>
                    </div>
                </div>
                <div className="characters-block">
                    <div className="characters-text-block">
                        <h3 className="characters-title">& More</h3>
                        <p className="characters-text">
                            There are more characters in the Harajuku Punks. Take a look and find your favorite!
                        </p>
                    </div>
                    <div className="characters-img-block">
                        <div className="characters-img">
                            <img src={Other1} />
                        </div>
                        <div className="characters-img">
                            <img src={Other2} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chara