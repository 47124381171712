import React from "react";
import "./Characters.css";
import Cat1 from "../../img/cat1.png";
import Cat2 from "../../img/cat2.png";
import Shiba1 from "../../img/shiba1.png";
import Shiba2 from "../../img/shiba2.png";
import Fox1 from "../../img/fox1.png";
import Fox2 from "../../img/fox2.png";
import Other1 from "../../img/other1.png";
import Other2 from "../../img/other2.png";

const Chara = () => {
    return (
        <div id="characters" className="characters">
            <div className="characters-content">
                <div className="characters-bg">
                    <div className="characters-bg-img" />
                </div>
                <div className="characters-block">
                    <div className="characters-text-block">
                        <h3 className="characters-title">Cat</h3>
                        <p className="characters-text">
                        今やペット界一のアイドル「ねこ」。あのダヴィンチに、そのかわいらしさは芸術として最高傑作である、なんて言われたことも。
                        </p>
                    </div>
                    <div className="characters-img-block">
                        <div className="characters-img">
                            <img src={Cat1} />
                        </div>
                        <div className="characters-img">
                            <img src={Cat2} />
                        </div>
                    </div>
                </div>
                <div className="characters-block">
                    <div className="characters-text-block">
                        <h3 className="characters-title">Shibainu</h3>
                        <p className="characters-text">
                        世界中で大人気の小型犬「しばいぬ」。忠誠心が強く、賢い。それでいてかわいい日本の古代犬。
                        </p>
                    </div>
                    <div className="characters-img-block">
                        <div className="characters-img">
                            <img src={Shiba1} />
                        </div>
                        <div className="characters-img">
                            <img src={Shiba2} />
                        </div>
                    </div>
                </div>
                <div className="characters-block">
                    <div className="characters-text-block">
                        <h3 className="characters-title">Fox</h3>
                        <p className="characters-text">
                        お稲荷様として信仰の対象になったり、妖怪として人を化かすなんて言われたり、いくつもの顔をもつ不思議な生き物。
                        </p>
                    </div>
                    <div className="characters-img-block">
                        <div className="characters-img">
                            <img src={Fox1} />
                        </div>
                        <div className="characters-img">
                            <img src={Fox2} />
                        </div>
                    </div>
                </div>
                <div className="characters-block">
                    <div className="characters-text-block">
                        <h3 className="characters-title">& More</h3>
                        <p className="characters-text">
                        他にもいろいろな動物がいるよ。好きな動物を探してみてね！
                        </p>
                    </div>
                    <div className="characters-img-block">
                        <div className="characters-img">
                            <img src={Other1} />
                        </div>
                        <div className="characters-img">
                            <img src={Other2} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chara