import React, { useEffect } from "react";
import "./Logo.css";

const Logo = () => {
    
    // Scale Logo
    useEffect(() => {
        window.addEventListener('scroll', scaleLogo);
        return () => {
            window.removeEventListener('scroll', scaleLogo);
        };
    });

            
    /* Method that will scale logo after a specific scrollable */
    const scaleLogo = (e) => {
        const logo = document.querySelector('.logo');
        const scrollTop = window.scrollY;
        scrollTop >= 1 ? logo.classList.add('scale') : logo.classList.remove('scale');
    };
    

    return (
        <div>
            <a href="/jp" className="logo" />
        </div>
    )
}

export default Logo