import React from "react";
import "./Roadmap.css";

const Roadmap = () => {
    return (
        <div id="roadmap" className="roadmap">
            <div className="roadmap-content">
                <h2 className="roadmap-title">Roadmap</h2>
                <div className="timeline">
                    <div className="line" />
                    <div className="phase">
                        <div className="phase-left">
                            <div className="roadmap-bg-1">
                                <div className="roadmap-bg-img-1" />
                            </div>
                        </div>
                        <div className="phase-right">
                            <div className="phase-details">
                                <div className="phase-text-area">
                                    <h3 className="phase-title">
                                    NFTアート仕上げ
                                    </h3>
                                    <p className="phase-text">
                                    HJKトークンのミント開始まであとわずか！<br/><br/>
                                    Twitter、Instagram、Discordで最新情報をチェック。
                                    </p>
                                </div>                           
                            </div>
                        </div>
                    </div>
                    <div className="phase">
                        <div className="phase-left">
                            <div className="phase-details">
                                <div className="current">
                                    <p className="current-text">
                                        We're here!
                                    </p>
                                </div>
                                <div className="phase-text-area">
                                    <h3 className="phase-title">
                                    ミント受付開始！
                                    </h3>
                                    <p className="phase-text">
                                    当サイトにて、ミントが可能になります。<br/><br/>
                                    最初の100点に限り、1点あたり0.02ETHでミント受付いたします。
                                    </p>
                                </div>                           
                            </div>
                        </div>
                        <div className="phase-right">
                            <div className="roadmap-bg-2">
                                <div className="roadmap-bg-img-2" />
                            </div>
                        </div>
                    </div>
                    <div className="phase">
                        <div className="phase-left">
                            <div className="roadmap-bg-3">
                                <div className="roadmap-bg-img-3" />
                            </div>
                        </div>
                        <div className="phase-right">
                            <div className="phase-details">
                                <div className="phase-text-area">
                                    <h3 className="phase-title">
                                    ミント可能枠の、更なる開放！
                                    </h3>
                                    <p className="phase-text">
                                    1点あたり0.02ETH+のバリューで、ミント可能枠を開放します。<br/><br/>
                                    最初の100点のHJKホルダーさまと共に、更なる価値向上を目指します！
                                    </p>
                                </div>                           
                            </div>
                        </div>
                    </div>
                    <div className="phase">
                        <div className="phase-left">
                            <div className="phase-details">
                                <div className="phase-text-area">
                                    <h3 className="phase-title">
                                    HJKコミュニティ
                                    </h3>
                                    <p className="phase-text">
                                    DiscordにてHJK購入者限定のプライベートコミュニティ始動！
                                    </p>
                                </div>                           
                            </div>
                        </div>
                        <div className="phase-right">
                            <div className="roadmap-bg-4">
                                <div className="roadmap-bg-img-4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roadmap