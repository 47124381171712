import React from "react";
import "./Impression.css";
import Navigation from "../header/navigation/Navigation";
import Tslider from "../tslider/Tslider";
import ImpBgM from "../../img/imp-bg-m.png";

const Imp = () => {
    return (
        <div className="impression">
            <div className="imp-bg">
                <div className="imp-bg-img" />
                <img src={ImpBgM} alt="HJK" />
            </div>
            <div className="imp-tslider">
                <Tslider />
            </div>
            <Navigation />
            <div className="imp-block">
                <h1 className="imp-title">HARAJUKU<br/> PUNKS</h1>
                <p className="imp-text">
                    The first drop has started!<br/>
                    Get 1 HJK and join the airdrop campaign to get another HJK!
                </p>
                <div className="clear" />
                <a href="https://mint.harajukupunks.com" target="_blank" rel="noreferrer" className="imp-mint-btn">Mint Now</a>       
            </div>
        
        </div>
    )
}

export default Imp