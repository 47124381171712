import React from "react";
import Faq from "./Faq.js"
import "./Faq.css";

const Faqs = () => {
    return (
        <div className="faqs">
            <div className="faqs-content">
                <div className="faqs-bg">
                    <div className="faqs-bg-img" />
                </div>
                <h2 className="faqs-title">Still on a fence?(FAQ)</h2>
                <div className="faqs-block">
                    <Faq question="What is NFT?" answer="NFT stands for “Non-fungible token” and is a cool way of saying it’s a truly unique digital item that YOU can buy, own, and trade." />
                    <Faq question="What is METAMASK?" answer="Metamask is a crypto-wallet that can store your Ethereum, and is needed to purchase and mint a Bored Weird Vitalik. Having a wallet gives you an Ethereum address (i.e. 0xaC2x….000), this is where your NFT will be stored." />
                    <Faq question="How does this benefit me?" answer="Other than collecting valuable and 100% unique NFT art piece, you can invest in advance in Japan NFT Market that still offers plenty of growth potential." />
                    <Faq question="Where can I see my Harajuku Punks after purchase?" answer="Your Harajuku Punks NFT will appear in metamask wallet. You can also see them on your OpenSea account." />
                    <Faq question="What can I do with my Harajuku Punks / How can I trade them?" answer="You are free to do anything with them under a non-exclusive license. The Harajuku Punks adheres to the ERC-721 standard so you can trade them on platforms like OpenSea." />
                </div>
            </div>
        </div>  
)
}

export default Faqs