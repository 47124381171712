import React from "react";
import "./Tslider.css";

const Tslider = () => {
    return (
        <div className="tslider">
            <div className="text-slider">
                <div className="slider-text">
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                </div>
                <div className="slider-text">
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                    <span>HARAJUKU PUNKS</span>
                </div>   
            </div>
        </div>
    )
}

export default Tslider