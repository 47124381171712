import React from "react";
import "./Style.css";
import "./Style.js";
import Header from "./jcomponents/header/Header";
import Imp from "./jcomponents/impression/Impression";
import Details from "./jcomponents/details/Details";
import Chara from "./jcomponents/characters/Characters";
import Mint from "./jcomponents/mint/Mint";
import Roadmap from "./jcomponents/roadmap/Roadmap";
import Team from "./jcomponents/team/Team";
import Faqs from "./jcomponents/faq/Faqs";
import Footer from "./jcomponents/footer/Footer";


const Jp = () => {
    return(
        <div className="wrapper jp">
            <div className="wrapper-bg" />
            <Header />
            <Imp />
            <Details />
            <Chara />
            <Roadmap />
            <Mint />
            <Team />
            <Faqs />
            <Footer />  
        </div>     
    )
}
export default Jp