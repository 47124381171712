import React from "react";
import { Helmet } from "react-helmet";
import Favicon from "./templates/img/favicon.ico";
import TwitterCard from "./templates/img/tc.png";

const Head = () => {
    return (
        <Helmet>
            <meta charset="utf-8" />
            <title>HARAJUKU PUNKS</title>  
            <link rel="icon" href={Favicon} />
            <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0"></meta>
            <meta
            name="description"
            content="10000 unique NFT collectibles from Japan, living on the Ethereum Blockchain."
            />
            <meta name="keywords"
            content="nft, nfts, mint, collectibles, blockchain, bitcoin, crypto, cryptocurrency, btc, eth, coin, shiba, trading, buy"
            />
            {/* OGP ここから */}
            <head prefix="og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# website: http://ogp.me/ns/website#" />
            <meta property="og:url" content="https://harajukupunks.com" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="HARAJUKU PUNKS" />
            <meta property="og:description" content="10000 unique NFT collectibles from Japan, living on the Ethereum Blockchain." />
            <meta property="og:site_name" content="HARAJUKU PUNKS" />
            <meta property="og:image" content={TwitterCard} />
            {/* OGP ここまで */}
            {/* Twitter Card ここから */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@HJKPunks" />
            <meta name="twitter:title" content="HARAJUKU PUNKS" />
            <meta name="twitter:description" content="10000 unique NFT collectibles from Japan, living on the Ethereum Blockchain." />
            <meta name="twitter:image" content={TwitterCard} />
            {/* Twitter Card ここまで */}
        </Helmet>
    )
}

export default Head