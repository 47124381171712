import React from "react";
import "./Team.css";
import member1 from "../../img/team1.png";
import member2 from "../../img/team2.png";

const Team = () => {
    return (
        <div className="team">
            <h2 className="team-title">TEAM</h2>
            <div className="team-block">
                <div className="team-member">
                    <div className="team-member-img">
                        <img src={member1} />
                    </div>
                    <h4 className="team-member-name">Kei</h4>
                    <h5 className="team-member-position">Artist</h5>
                </div>
                <div className="team-member">
                    <div className="team-member-img">
                        <img src={member2} />
                    </div>
                    <h4 className="team-member-name">Bungo</h4>
                    <h5 className="team-member-position">UI/UX</h5>
                </div>
            </div>
        </div>
    )
}

export default Team